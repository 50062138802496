<template lang="pug">
  .footer.d-flex.align-items-center
    .buttons-container.d-flex.align-items-center.justify-content-between
      AppIconButton.delete.trash-icon(
        :disabled="!deleteEnabled"
        icon="trash-alt"
        @click="$emit('delete')"
        :title="$t('company_system.shop_settings.shop_form.delete_button')"
      )
      AppButton.save(
        :title="$t('company_system.shop_settings.shop_form.save_button')"
        @click="$emit('save')"
      )
</template>

<script>
  export default {
    components: {
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other"),
      AppButton: () => import("@/components/elements/AppButton")
    },

    props: {
      deleteEnabled: {
        type: Boolean,
        default: false
      },
      addLanguageEnabled: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .footer
    width: 100%

  .buttons-container
    width: 100%
    margin-top: 24px

    .save
      background-color: $default-purple
      color: $default-white

    .delete
      background-color: $default-red
      border-color: $default-red

      ::v-deep
        svg
          path
            fill: $default-white

        .title
          color: $default-white
</style>
